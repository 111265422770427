//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jq from "jquery";
import * as animJS from "~/modules/Animation";
export default {
  data() {
    return {
      openSubMenu: "",
      communitiesMenu: [
        {
          menuText: "CONDOS",
          menuLink: "no-link",
          subMenu: [
            {
              menuText: "ARTISTRY",
              menuLink: "/artistry",
            },
            {
              menuText: `LINX`,
              menuLink: "/reserve_collection",
            },
            // {
            //   menuText: `THE RESERVE COLLECTION`,
            //   menuLink: "/reserve_collection",
            // },
            // {
            //   menuText: "MAX CONDOS",
            //   menuLink: "/max_condos",
            // },
            // {
            //   menuText: "STANLEY",
            //   menuLink: "/stanley",
            // },
            // {
            //   menuText: "U.C TOWER",
            //   menuLink: "/uc_tower",
            // },
            {
              menuText: "U.C TOWER 2",
              menuLink: "/uc_tower2",
            },
            {
              menuText: "U.C TOWER 3",
              menuLink: "/uc_tower3/",
            },
            {
              menuText: "VUPOINT",
              menuLink: "/vupoint",
            },
            {
              menuText: "VUPOINT 2",
              menuLink: "/vupoint2/",
            },
            {
              menuText: "Y&S",
              menuLink: "/y_s",
            },
          ],
        },
        {
          menuText: "HOMES",
          menuLink: "no-link",
          subMenu: [
            {
              menuText: "COLGAN CROSSING",
              menuLink: "/colgan_crossing",
            },
            {
              menuText: "COBOURG TRAILS",
              menuLink: "/cobourg_trails",
            },
            {
              menuText: "COURTICE GLEN",
              menuLink: "/courtice_glen/",
            },
            {
              menuText: "GATES OF NOBLETON",
              menuLink: "/gates_of_nobleton/",
            },
            // {
            //   menuText: "CHELSEA HILL",
            //   menuLink: "/chelsea_hill",
            // },
            {
              menuText: "LINDSAY HEIGHTS",
              menuLink: "/lindsay_heights/",
            },
            // {
            //   menuText: "HUMBERWOOD HEIGHTS",
            //   menuLink: "/humberwood_heights",
            // },
            // {
            //   menuText: "HYLAND VILLAGE",
            //   menuLink: "/hyland_village",
            // },
            // {
            //   menuText: "ROUGE VALLEY",
            //   menuLink: "/rouge_valley",
            // },
            // {
            //   menuText: "STONEMANOR WOODS",
            //   menuLink: "/stonemanor_woods",
            // },
            {
              menuText: "STORYBROOK",
              menuLink: "/storybrook",
            },
            // {
            //   menuText: "U.C",
            //   menuLink: "/uc",
            // },
            // {
            //   menuText: "U.C TOWNS",
            //   menuLink: "/uc_towns",
            // },
          ],
        },
        {
          menuText: "RENTALS",
          menuLink: "no-link",
          subMenu: [
            {
              menuText: "PARKSIDE SQUARE",
              menuLink: "https://www.parksidesquare.com",
            },
          ],
        },
        {
          menuText: "COMING SOON",
          menuLink: "/coming_soon",
          // menuLink: "no-link",
          // subMenu: [
            // {
            //   menuText: "UC TOWNS",
            //   menuLink: "/uc_towns",
            // },
            // old
            // {
            //   menuText: "ALL",
            //   menuLink: "/coming_soon",
            // },
            //   {
            //     menuText: "COLGAN CROSSING",
            //     menuLink: "/coming_soon?goto=comingsoon-colgan-crossing",
            //   },
            //   {
            //     menuText: "U.C TOWER 2",
            //     menuLink: "/coming_soon?goto=comingsoon-uc-tower2",
            //   },
            //   {
            //     menuText: "COBOURG TRAILS",
            //     menuLink: "/coming_soon?goto=comingsoon-cobourg-trails",
            //   },
            //   {
            //     menuText: "COMING SOON LIST",
            //     menuLink: "/coming_soon/",
            //   },
            //   {
            //     menuText: "VUPOINT 2",
            //     menuLink: "/vupoint2/",
            //   },
            //   {
            //     menuText: "EVERLY",
            //     menuLink: "/everly/",
            //   },
            //   {
            //     menuText: "U.C TOWER 3",
            //     menuLink: "/uc_tower3/",
            //   },
            //   end
            // {
            //   menuText: "BROOKLIN TRAILS",
            //   menuLink: "/brooklin_trails/",
            // },
            // {
            //   menuText: "COURTICE GLEN",
            //   menuLink: "/courtice_glen/",
            // },
            // {
            //   menuText: "GATES OF NOBLETON",
            //   menuLink: "/gates_of_nobleton/",
            // },
            //     /**{
            //       menuText: "LINDSAY HEIGHTS",
            //       menuLink: "/lindsay_heights/",
            //     },*/
            // {
            //   menuText: "VICTORIA",
            //   menuLink: "/victoria/",
            // },
            // {
            //   menuText: "NOYO",
            //   menuLink: "/noyo/",
            // },
            // {
            //   menuText: "THE JOPLING",
            //   menuLink: "/jopling/",
            // },
          // ],
        },
        {
          menuText: "PAST COMMUNITIES",
          menuLink: "/past_communities",
        },
        // {
        //   menuText: "PORTAL",
        //   menuLink: "no-link",
        //   subMenu: [
        //     {
        //       menuText: "U.C. TOWER 2 - BORKER PORTAL",
        //       menuLink: "/uctower2-brokerportal/",
        //     },
        //   ]
        // }
      ],
      aboutusMenu: [
        {
          menuText: "CORE VALUES",
          menuLink: "/core_values",
        },
        {
          menuText: "AWARDS",
          menuLink: "/awards",
        },
        {
          menuText: "CUSTOMER SERVICE",
          menuLink: "/customer_service",
        },
        {
          menuText: "DECOR STUDIO",
          menuLink: "/decor_studio",
        },
        {
          menuText: "CAREERS",
          menuLink: "/career",
        },
      ],
      videosMenu: [
        {
          menuText: "TRIBUTE VIDEOS",
          menuLink: "/videos",
        },
        {
          menuText: "HOT PROPERTY",
          menuLink: "/hot-property",
        },
      ],
      showMenu: false,
      showMenu_D: true,
      showIt: "",
      scrollToShorten: false,
    };
  },
  mounted() {
    // console.log(this.showMenu_D);
    jq(window).on("scroll", () => {
      this.scrollFromTop = jq(window).scrollTop();
      if (this.scrollFromTop > 50) {
        this.scrollToShorten = true;
      } else {
        this.scrollToShorten = false;
      }
    });

    jq(window).on("resize", () => {
      if (jq(window).width() < 997) {
        this.showMenu_D = true;
      } else {
        this.showMenu_D = true;
      }
    });
    jq(".menu_D .menu_l1")
      .children()
      .on("click", function () {
        // console.log("children", $(this).parent());
        if ($(this).parent().hasClass("menu_l1")) {
          $(this).parent().removeClass("mouseIn");
          $(".menuToggle_D .showMenu_D").click();
        }
      });
    jq(".menu_D .menu_l1").hover(
      function () {
        // console.log("mouseIn-0");
        if ($(this).hasClass("menu_l1")) {
          $(this).addClass("mouseIn");
          animJS.gsapUpdateDom(
            ".menu_D .menu_l1 .menu_l2",
            {
              opacity: 0,
              scale: 0.9,
              transformOrigin: "top center",
            },
            {
              opacity: 1,
              scale: 1,
              transformOrigin: "top center",
              ease: "power2.in",
              duration: 0.1,
            }
          );
          // console.log("mouseIn", e.target);
        }
      },
      function () {
        // console.log("mouseOut-0");
        if ($(this).hasClass("menu_l1")) {
          $(this).removeClass("mouseIn");
          // console.log("mouseOut", e.target);
        }
      }
    );
    jq(".menu_D .menu_l1 .menu_l2 .item .subMenu").hover(
      function () {
        // console.log("mouseIn-0");
        // if ($(this).hasClass("menu_l1")) {
        $(this).prev().addClass("alived");
        // console.log("mouseIn", e.target);
        // }
      },
      function () {
        // console.log("mouseOut-0");
        // if ($(this).hasClass("menu_l1")) {
        $(this).prev().removeClass("alived");
        // console.log("mouseOut", e.target);
        // }
      }
    );
  },
  methods: {
    showSubMenu(currentMenu) {
      if (this.showIt == currentMenu) {
        this.showIt = "";
      } else {
        this.showIt = currentMenu;
      }
    },
    toggleSubSubMenu(payload) {
      if (this.openSubMenu == payload) {
        this.openSubMenu = "";
      } else {
        this.openSubMenu = payload;
      }
    },
  },
};
